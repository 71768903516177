<style>
.vue__time-picker-dropdown {
  z-index: 5000;
}
</style>
<template>
  <div style="margin: 0px 10px">
    <div class="row">
      <div class="col-md-12">
        <h2 class="pull-left">Disponibilità Medici</h2>
        <div class="pull-right" style="margin-top: 20px">
          <button type="button" class="close" aria-label="Close" @click="close()"><span aria-hidden="true">&times;</span></button>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ invalid }">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <div class="row">
              <label class="col-sm-2 control-label" for="doctor">Dottore:</label>
              <div class="col-sm-10">
                <ValidationProvider name="doctor_id" rules="required" v-slot="{ errors }">
                  <select v-model="doctor_id" class="form-control" name="doctor_id" v-select2 required :disabled="doctors.length == 1">
                    <option v-for="doctor in doctors" :key="'doctor_' + doctor.id" :value="doctor.id">
                      {{ doctor.name }}
                    </option>
                  </select>
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-sm-2 control-label" for="time_start">Frequenza:</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <input v-model="frequency" class="form-control" type="number" name="frequency" readonly />
                  <span class="input-group-addon"> minuti </span>
                </div>
              </div>
              <label class="col-sm-2 control-label" for="date">Data:</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <input :value="date" class="form-control" type="text" name="date" readonly />
                  <span class="input-group-addon">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-sm-2 control-label" for="time_start">Inizio:</label>
              <div class="col-sm-4">
                <ValidationProvider name="start" rules="required" v-slot="{ errors }">
                  <input type="time" name="start" v-model="start" class="form-control" min="00:00" :max="end" required />
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-sm-2 control-label" for="date">Fine:</label>
              <div class="col-sm-4">
                <ValidationProvider name="end" rules="required" v-slot="{ errors }">
                  <input type="time" v-model="end" class="form-control" :min="start" max="23:59" required />
                  <span>{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" style="margin-bottom: 15px">
        <div class="col-md-12 text-right">
          <button class="btn btn-success" @click="save()" :disabled="invalid"><i class="fa fa-save" aria-hidden="true"></i> Salva modifiche</button>
          <button class="btn btn-primary" @click="close()"><i class="fa fa-times" aria-hidden="true"></i> Chiudi</button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: {
    doctors: Array,
    date: String,
    ambulatory_id: Number,
  },
  mounted() {
    let vm = this;
    $("select[name='doctor_id']").select2();
    $("select[name='doctor_id']").on("select2:select", function (e) {
      let doctor = vm.doctors.find((x) => x.id === parseInt(this.value));
      console.log("Selezionato " + doctor.name);
      vm.frequency = doctor.frequency;
    });
  },
  data: function () {
    let doctor_id = null;
    let frequency = null;

    if (this.doctors.length == 1) {
      doctor_id = this.doctors[0].id;
      frequency = this.doctors[0].frequency;
    }

    return {
      doctor_id: doctor_id,
      frequency: frequency,
      start: "00:00",
      end: "23:30",
      srvErrors: [],
    };
  },
  methods: {
    close() {
      this.$emit("close", true);
    },
    save() {
      let vm = this;
      axios
        .post("/appointments/availability", {
          date: this.date,
          doctor_id: this.doctor_id,
          ambulatory_id: this.ambulatory_id,
          time_start: this.start,
          time_stop: this.end,
        })
        .then((res) => {
          console.log(res);
          vm.$parent.$emit("update");
          vm.close();
        })
        .catch((err) => {
          console.log(err);
          vm.srvErrors = err.response.data.errors;
          bootbox.alert("Errore!");
        });
    },
  },
};
</script>
